// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-page-js": () => import("./../../../src/components/pages/page.js" /* webpackChunkName: "component---src-components-pages-page-js" */),
  "component---src-components-posts-archives-js": () => import("./../../../src/components/posts/archives.js" /* webpackChunkName: "component---src-components-posts-archives-js" */),
  "component---src-components-posts-blog-post-js": () => import("./../../../src/components/posts/blog-post.js" /* webpackChunkName: "component---src-components-posts-blog-post-js" */),
  "component---src-components-posts-post-list-js": () => import("./../../../src/components/posts/post-list.js" /* webpackChunkName: "component---src-components-posts-post-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

